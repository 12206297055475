import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Box from "../containers/box";
import Button from "../buttons/button";
import Circle from "../globals/circle";

const Root = styled(Box)`
  display: block;
  margin: 0 auto 90px auto;
  position: relative;

  @media (min-width: ${p => p.theme.screenL}) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 0;
  color: ${p => p.theme.greyDark};
  background-color: ${p => p.theme.greyLightest};
  border: 1px solid ${p => p.theme.grey};
  position: relative;
  z-index: 5;
  border-radius: ${p => p.theme.borderRadiusSqBtn};

  ${p => p.active
    && `
      color: ${p.theme.white};
      background-color: ${p.theme.blueDark};
    `};
`;

const MobilePayPlanButtons = ({ onClick, activePlanType, theme }) => {
  return (
    <Root>
      <StyledButton
        onClick={() => onClick(`Parent Portal`)}
        active={activePlanType === `Parent Portal`}
      >
        Parent Portal
      </StyledButton>

      <StyledButton
        onClick={() => onClick(`Coach Portal`)}
        active={activePlanType === `Coach Portal`}
      >
        Coach Portal
      </StyledButton>

      <StyledButton
        onClick={() => onClick(`Clubs`)}
        active={activePlanType === `Clubs`}
      >
        Clubs
      </StyledButton>

      <Circle
        size="s"
        bgColor={theme.red}
        bottom={[-30, null, -46, -90]}
        right="10%"
      />
    </Root>
  );
};

MobilePayPlanButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  activePlanType: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(MobilePayPlanButtons);
