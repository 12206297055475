import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import { Relative } from "../containers/position";
import Banner from "../containers/banner";
import Heading from "../typography/heading";
import LinkButton from "../links/linkButton";
import Circle from "../globals/circle";
import MaxWidthAbsolute from "../containers/maxWidthAbsolute";

const FreeTrialBanner = ({ theme, ...rest }) => {
  return (
    <Banner
      bg={theme.green}
      textAlign="center"
      mx="auto"
      pt={100}
      pb={94}
      {...rest}
    >
      <Relative zIndex={5}>
        <Heading
          as="h2"
          fontSize="48px"
          color={theme.white}
          mx="auto"
          mb="30px"
          maxWidth="678px"
        >
          Try TopTekkers for FREE with our 7–day trial.
        </Heading>

        <LinkButton
          as="a"
          href="https://app.toptekkers.club"
          bg={theme.white}
          color={theme.greyDark}
        >
          Create an account
        </LinkButton>
      </Relative>

      <MaxWidthAbsolute>
        <Circle size="m" top="-45px" left="10%" bgColor={theme.pink} />
        <Circle size="l" bottom="20%" left="-83px" bgColor={theme.orange} />
        <Circle size="l" bottom="-84px" right="14%" bgColor={theme.blue} />
        <Circle size="l" top="-83px" right="-83px" bgColor={theme.yellow} />
      </MaxWidthAbsolute>
    </Banner>
  );
};

FreeTrialBanner.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(FreeTrialBanner);
