import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Box from "../containers/box";
import Button from "../buttons/button";

const ButtonsWrap = styled(Box)`
  display: block;
  max-width: 662px;
  margin: 0 auto 60px auto;
`;

const StyledButton = styled(Button)`
  padding: 18px 50px;
  color: ${p => p.theme.greyDark};
  background-color: ${p => p.theme.greyLightest};
  font-size: ${p => p.theme.baseFontSizeDesktop};
  min-width: 220px;

  border: 1px solid ${p => p.theme.grey};
  border-radius: 0;

  &:first-of-type {
    border-radius: 30px 0 0 30px;
  }
  &:last-of-type {
    border-radius: 0 30px 30px 0;
  }

  ${p => p.active
    && `
    color: ${p.theme.white};
    background-color: ${p.theme.green};
  `}
`;

const DesktopPlansFeatureButtons = ({ onClick, activePlanType }) => {
  return (
    <ButtonsWrap>
      <StyledButton
        onClick={() => onClick(`Parent Portal`)}
        active={activePlanType === `Parent Portal`}
      >
        Parent Portal
      </StyledButton>

      <StyledButton
        onClick={() => onClick(`Coach Portal`)}
        active={activePlanType === `Coach Portal`}
      >
        Coach Portal
      </StyledButton>

      <StyledButton
        onClick={() => onClick(`Clubs`)}
        active={activePlanType === `Clubs`}
      >
        Clubs
      </StyledButton>
    </ButtonsWrap>
  );
};

DesktopPlansFeatureButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  activePlanType: PropTypes.string.isRequired,
};

export default DesktopPlansFeatureButtons;
