export const paymentPlans = [
  {
    id: 1,
    imageName: `parentPlan`,
    imageAlt: `Parent and child in football kit.`,
    type: `Parent Portal`,
    price: `$3.99 / child per month`,
    priceInfo: `$34.99 billed annually`,
    planInfo: `Add family members to your portal to give them their own accounts. Track their progress and latest scores. Securely connect with their Coaches and Teams.`,
    buttonText: `Get your FREE 7–day trial`,
    buttonHref: `https://app.toptekkers.club`,
    buttonColor: `green`,
    features: [
      `Tailor the difficulty of challenges `,
      `Track Childrens activity and scores in your Parent Portal`,
      `Manage childrens account details to keep them secure and always be in control`,
      `Securely connect with Coaches and Teams that your children are part of`,
    ],
    featurePlanInfo: ` per child, per month`,
    released: true,
  },
  {
    id: 2,
    imageName: `coachPlan`,
    imageAlt: `Coach with two children stood behind him.`,
    type: `Coach Portal`,
    price: `FREE`,
    priceInfo: `TopTekkers is FREE for coaches.`,
    planInfo: `Create your own team and manage your players. Connect with Parents to add Players to your Team and then set Individual Development Plans.`,
    buttonText: `Contact sales team`,
    buttonHref: `/contact`,
    buttonColor: `blue`,
    features: [
      `Securely connect with the players you coach via their Parents email address`,
      `Create teams to quickly and easily manage your players`,
      `Give your players homework by assigning them Individual Development Plans`,
      `Track players scores, trophies and activity`,
    ],
    featurePlanInfo: `FREE`,
    released: true,
  },
  {
    id: 3,
    imageName: `clubPlan`,
    imageAlt: `TopTekkers logo mounted onto a shield.`,
    type: `Clubs`,
    price: `Tailor your cost`,
    priceInfo: `Use our online calculator to find the right cost for your organisation.`,
    planInfo: `Large-scale licensing for football clubs and organisations.`,
    buttonText: `Calculate subscription`,
    buttonHref: `/club-calculator`,
    buttonColor: `yellow`,
    features: [
      `Give players, parents and coaches at your club all the benefits of TopTekkers at a discounted rate`,
      `Revenue share model for early adopters`,
      `Flexible licence system can cater for clubs of any size`,
      `Simple on-boarding experience allows you to get all players, parents and coaches using TopTekkers quickly and efficiently`,
    ],
    featurePlanInfo: `Tailor your cost`,
    released: true,
  },
]
